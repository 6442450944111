type Props = { [s: string]: string }

export const primary = '#07A6BA'
export const primaryLighter = '#CFF3F3'
export const primaryLightest = '#F0FCFC'
export const secondary = '#4774E2'
export const secondaryLighter = '#CDE2FF'
export const secondaryLightest = '#EDF2FF'

export const black = '#1C2B3E'
export const white = '#fff'

export const grayDarkest = '#495D70'
export const grayDarker = '#657E99'
export const grayDark = '#88A0BA'
export const gray = '#ADC2D9'
export const grayLight = '#C5D4E2'
export const grayLighter = '#E3ECF5'
export const grayLightest = '#F2F5F7'

export const color: Props = {
  primary,
  primaryLighter,
  primaryLightest,
  secondary,
  secondaryLighter,
  secondaryLightest,
  black,
  white,
  grayDarkest,
  grayDarker,
  grayDark,
  gray,
  grayLight,
  grayLighter,
  grayLightest,
}

export const containerMd = '1120px'

export const container: Props = {
  md: containerMd,
}

export default {
  color,
  container,
}
