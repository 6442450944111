import React from 'react'
import styled from 'styled-components'
import { color } from '@/assets/styles/variables'

const Footer = styled.footer`
  width: 100%;
`
const Copyright = styled.div`
  font-size: 1.4rem;
  color: ${color.grayDarkest};
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
`

const Component: React.FC = () => (
  <Footer>
    <Copyright>OGP Validator © 2020</Copyright>
  </Footer>
)

export default Component
