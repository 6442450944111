import styled from 'styled-components'
import { color } from '@/assets/styles/variables'
import media from '@/assets/styles/media'

const Input = styled.input`
  font-size: 1.8rem;
  color: ${color.grayDarkest};
  width: 100%;
  height: 40px;
  background-color: ${color.white};
  border-radius: 6px;
  padding: 0.5em 1em;
  &::placeholder {
    color: ${color.gray};
  }
  @media ${media.sm} {
    font-size: 2rem;
    height: 46px;
    border-radius: 8px;
  }
  @media ${media.md} {
    font-size: 2.2rem;
    height: 50px;
    border-radius: 10px;
  }
  @media ${media.lg} {
    font-size: 2.4rem;
    height: 56px;
  }
`

export default Input
