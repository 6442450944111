import React from 'react'
import styled from 'styled-components'
import loadingSVG from '@/assets/images/loading.svg'
import { color } from '@/assets/styles/variables'

const Wrapper = styled.div`
  display: inline-block;
  font-size: 1.6rem;
  color: ${color.grayDark};
  text-align: center;
  max-width: 240px;
  width: 100%;
  & img {
    width: 100%;
  }
  & p {
    white-space: nowrap;
  }
`

const Loading: React.FC = props => {
  return (
    <Wrapper {...props}>
      <img src={loadingSVG} />
      <p>10〜20秒ほどかかります。</p>
    </Wrapper>
  )
}

export default Loading
