import React, { forwardRef } from 'react'
import styled from 'styled-components'
import ButtonBase from '@/components/Button/ButtonBase'
import { color } from '@/assets/styles/variables'
import media from '@/assets/styles/media'

const Button = styled(ButtonBase)`
  font-size: 1.8rem;
  color: ${color.grayDarker};
  line-height: 1.2;
  height: 48px;
  background-color: ${color.grayLighter};
  border: none;
  border-radius: 6px;
  /* box-shadow: 0px 8px 14px #01c3cc66; */
  padding: 0.8em 2em;
  /* transition: box-shadow 0.2s ease-in, transform 0.2s ease-in; */
  cursor: pointer;

  /* filter: drop-shadow(-6px -6px 12px ${color.grayLightest})
    drop-shadow(6px 6px 12px ${color.grayLight}); */
  box-shadow:
    5px 5px 10px ${color.grayLight},
    -5px -5px 10px ${color.grayLightest},
    inset 0 0 0 ${color.grayLight},
    inset 0 0 0 ${color.grayLightest};
  transition: box-shadow 0.1s ease-in;
  &[data-active='true'] {
    color: ${color.grayDarkest};
    background-color: ${color.grayLighter};
    box-shadow:
      0 0 0 ${color.grayLight},
      0 0 0 ${color.grayLightest},
      inset 3px 3px 6px ${color.grayLight},
      inset -3px -3px 6px ${color.grayLightest};
  }
  @media ${media.sm} {
    font-size: 2rem;
    height: 52px;
    border-radius: 8px;
  }
  @media ${media.md} {
    font-size: 2.2rem;
    height: 58px;
    border-radius: 10px;
  }
  @media ${media.lg} {
    font-size: 2.4rem;
    height: 64px;
  }
`

const Component = forwardRef<HTMLButtonElement, Record<string, unknown>>(
  (props, ref) => (
    <Button {...props} ref={ref}>
      OGPを更新
    </Button>
  )
)

Component.displayName = 'SubmitButton'

export default Component
