import React, { useState } from 'react'
import styled from 'styled-components'
import { AxiosError, AxiosResponse } from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import axios from '@/utils/axios'
import Layout from '@/components/Layout/LayoutDefault'
import SEO from '@/components/SEO'
import StructuredData from '@/components/StructuredData/Top'
import About from '@/components/Top/About'
import Main from '@/components/Top/Main'

const State = {
  NEW: 'new',
  WIP: 'wip',
  DONE: 'done',
} as const
export type State = typeof State[keyof typeof State]

const Type = {
  Twitter: 'twitter',
  Facebook: 'facebook',
} as const
export type Type = typeof Type[keyof typeof Type]

export type Result = {
  twitter?: string
  facebook?: string
}

export type ValidateResponse = AxiosResponse<Blob> | Error

export type DoValidateFunc = (
  type: Type,
  siteURL: string
) => Promise<ValidateResponse>

type CrawlerError = {
  name: 'CrawlerError'
  message?: string
  data: {
    image?: Buffer
    html?: string
  }
}
export type ValidateError = {
  error: CrawlerError | any
}

export type HandleSubmitFunc = (event: React.FormEvent, siteURL: string) => void

export type HandleErrorFunc = (message: string) => void

const StyledAlert = styled(Alert)`
  font-size: 1.4rem;
`

const blobToImageURL = (blob: Blob): string => {
  return URL.createObjectURL(blob)
}
// const readFileAsync = (file: Blob) => {
const readFileAsync = (file: Blob): Promise<ValidateError> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(JSON.parse(reader.result))
      }
      reject('解析に失敗しました')
    }
    reader.onerror = () => {
      reject(reader.error?.message || '解析に失敗しました')
    }
    reader.readAsText(file)
  })
}

const urlRegExp = /https?:\/\/[\w!?/+-_~=;.,*&@#\\$%()'[\]]+/
const validateURL = (value: string): boolean => {
  return urlRegExp.test(value)
}

const IndexPage: React.FCPage = ({ location }) => {
  const [state, setState] = useState<State>(State['NEW'])
  const [result, setResult] = useState<Result>({})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const doValidate: DoValidateFunc = async (type, siteURL) => {
    const path = process.env.GATSBY_API_VALIDATE_PATH || ''
    const response = await axios
      .post(
        path,
        {
          type,
          siteURL,
        },
        {
          responseType: 'blob',
        }
      )
      .catch(async (err: AxiosError<Blob>) => {
        if (!err.response) {
          return new Error('通信に失敗しました')
          // err.response = {
          //   status: 400,
          //   data: { error: '通信に失敗しました' },
          // }
        } else {
          console.log(err)
          // return new Error(err.message)
          const data = await readFileAsync(err.response.data).catch(
            e => new Error(e)
          )

          if (data instanceof Error) {
            return data
          } else {
            // if (data.error?.name === 'CrawlerError') {
            //   const error = data.error as CrawlerError
            //   sendErrorDebug(
            //     type,
            //     error.message || 'no message',
            //     error.data.image,
            //     error.data.html
            //   )
            //   return new Error(data.error?.message)
            // }
            return new Error(data.error?.message)
          }
          // err.response.data = error
        }
      })
    return response
  }

  const handleSubmit: HandleSubmitFunc = async (event, siteURL) => {
    event.preventDefault()
    if (!validateURL(siteURL)) {
      handleError('入力されたURLが不適切です')
      return
    }
    const timer = setTimeout(() => setState('wip'), 500)
    // setState('wip')
    if (typeof ga === 'function') {
      ga('send', 'event', 'validate', 'submit', siteURL)
    }
    const [twitterResult, facebookResult] = await Promise.all([
      doValidate('twitter', siteURL),
      doValidate('facebook', siteURL),
    ])
    if (!twitterResult || !facebookResult) {
      handleError('通信エラーが発生しました')
      clearTimeout(timer)
      return
    }
    if (twitterResult instanceof Error) {
      handleError(twitterResult.message)
      clearTimeout(timer)
    } else {
      const twitterData = twitterResult.data
      setResult(state => ({
        ...state,
        twitter: blobToImageURL(twitterData),
      }))
    }

    if (facebookResult instanceof Error) {
      handleError(facebookResult.message)
      clearTimeout(timer)
    } else {
      const facebookData = facebookResult.data
      setResult(state => ({
        ...state,
        facebook: blobToImageURL(facebookData),
      }))
    }
    setState('done')
  }

  const handleError: HandleErrorFunc = (message: string) => {
    setErrorMessage(message)
    setState('new')
  }

  return (
    <Layout>
      <SEO title="Twitter & Facebook OGP更新ツール" location={location} />
      <StructuredData />
      <Main result={result} state={state} handleSubmit={handleSubmit} />
      <About />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage('')}
      >
        <StyledAlert severity="error">{errorMessage}</StyledAlert>
      </Snackbar>
    </Layout>
  )
}
export default IndexPage
