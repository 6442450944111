import React from 'react'
import LayoutBase from '@/components/Layout/LayoutBase'
// import Header from '@/components/Layout/HeaderDefault'
import Footer from '@/components/Layout/FooterDefault'

const Layout: React.FC = ({ children }) => (
  <LayoutBase>
    {/* <Header /> */}
    <main>{children}</main>
    <Footer />
  </LayoutBase>
)

export default Layout
