import React from 'react'
import styled from 'styled-components'
import { container, color } from '@/assets/styles/variables'
import FeatureImage from '@/assets/images/feature_image.svg'
import media from '@/assets/styles/media'

const Wrapper = styled.div`
  text-align: center;
  max-width: ${container.md};
  padding: 100px 20px;
  margin: 0 auto;
`
const Img = styled.img`
  width: 560px;
  margin-bottom: 60px;
  @media ${media.sm} {
    margin-bottom: 100px;
  }
  @media ${media.md} {
    width: 640px;
  }
  @media ${media.lg} {
    width: 760px;
  }
  @media ${media.xl} {
    width: 900px;
  }
`
const Heading = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${color.grayDarkest};
  line-height: 2;
  text-align: center;
  margin-bottom: 2em;
  @media ${media.sm} {
    font-size: 2rem;
  }
  @media ${media.md} {
    font-size: 2.2rem;
  }
  @media ${media.xl} {
    font-size: 2.4rem;
  }
  & span {
    display: inline-block;
  }
`
const Section = styled.section`
  margin-top: 100px;
  margin-bottom: 100px;

  @media ${media.md} {
    margin-top: 140px;
    margin-bottom: 140px;
  }
  @media ${media.xl} {
    margin-top: 180px;
    margin-bottom: 180px;
  }
`
const Title = styled.h2`
  position: relative;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${color.grayDarkest};
  line-height: 1.5;
  letter-spacing: 0.06em;
  padding-bottom: 0.2em;
  margin-bottom: 2em;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1.5em;
    height: 2px;
    background-color: ${color.grayDarkest};
    margin-left: 50%;
    transform: translateX(-50%);
  }
  @media ${media.xl} {
    font-size: 3rem;
  }
`
const Ul = styled.ul``
const Li = styled.li`
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 0;
  }
`
const Label = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: ${color.grayDarkest};
  margin-bottom: 1.5em;
  @media ${media.xl} {
    font-size: 2.4rem;
  }
`
const DescriptionStyled = styled.div`
  font-size: 1.6rem;
  color: ${color.grayDarkest};
  line-height: 1.5;
  word-break: break-all;
  & p {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & a {
    text-decoration: underline;
  }
  & br[data-md-only] {
    display: none;
  }
  @media ${media.md} {
    & br[data-md-only] {
      display: inline;
    }
  }
  @media ${media.xl} {
    font-size: 1.8rem;
  }
`

const info: { label: string; description: React.FC }[] = [
  {
    label: 'Puppeteer',
    description() {
      return (
        <DescriptionStyled>
          あなたの代わりにTwitterとFacebookのOGP情報の更新を自動で行います。
          <br />
          サーバー側でヘッドレスブラウザを自動操作して、同時にかつ高速に処理できます。
          <br />
          <a
            href="https://github.com/puppeteer/puppeteer"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/puppeteer/puppeteer
          </a>
        </DescriptionStyled>
      )
    },
  },
  {
    label: 'Twitter',
    description() {
      return (
        <DescriptionStyled>
          公式ツール Card
          validatorを利用して、シェアするデータの更新を行います。
          <br />
          <a
            href="https://cards-dev.twitter.com/validator"
            target="_blank"
            rel="noreferrer"
          >
            https://cards-dev.twitter.com/validator
          </a>
        </DescriptionStyled>
      )
    },
  },
  {
    label: 'Facebook',
    description() {
      return (
        <DescriptionStyled>
          公式ツール
          シェアデバッガーを使って、シェアするデータの更新を行います。
          <br />
          <a
            href="https://developers.facebook.com/tools/debug/"
            target="_blank"
            rel="noreferrer"
          >
            https://developers.facebook.com/tools/debug/
          </a>
        </DescriptionStyled>
      )
    },
  },
]

const Component: React.FC = () => {
  return (
    <Wrapper>
      <Img src={FeatureImage}></Img>
      <Heading>
        <span>OGPを変更したページの</span>
        <span>URLを入力するだけ！</span>
        <br />
        Twitter & FacebookのOGP情報を
        <br />
        一括更新できます。
      </Heading>
      <Section>
        <Title>仕組み</Title>
        <Ul>
          {info.map(({ label, description: Description }, index) => {
            // const Description = React.createElement(description, { key: index })
            return (
              <Li key={index}>
                <Label>{label}</Label>
                <Description />
              </Li>
            )
          })}
        </Ul>
      </Section>
      <Section>
        <Title>目的</Title>
        <Ul>
          <Li>
            <DescriptionStyled>
              <p>
                ブログ記事がはじめてシェアされる際、OGP画像がSNSに読み込まれておらず、
                <br data-md-only />
                残念な投稿になることたまにありますよね。。。
                <br data-md-only />
                画像があるとなしとでは、エンゲージ率もクリック率も大きく変わってきます。
              </p>
              <p>
                ですから、記事を作成したらTwitterとFacebookにOGP画像を読み込ませる作業を毎回していました。
                <br data-md-only />
                このルーチンワークをどうにか自動化できないかなということでOGPValidatorを開発しました。
              </p>
              <p>
                せっかくのシェアを無駄にしないためにも、ぜひ活用いただければと思います。
              </p>
            </DescriptionStyled>
          </Li>
        </Ul>
      </Section>
    </Wrapper>
  )
}

export default Component
