import React from 'react'
import { StylesProvider } from '@material-ui/core/styles'
import App from '@/components/App'

const Layout: React.FC = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <App />
      {children}
    </StylesProvider>
  )
}

export default Layout
