import React from 'react'
import { Helmet } from 'react-helmet'
import { Organization, WithContext } from 'schema-dts'
import { useStaticQuery, graphql } from 'gatsby'
import { CreateBreadcrumbList } from '@/components/StructuredData/BreadcrumbList'

const pathToURL = (path: string): string => {
  const url = new URL(path, process.env.GATSBY_URL)
  if (!url) {
    return ''
  }
  return url.href
}

const Component: React.FC = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
        }
      }
    }
  `)
  const organizationSchema: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    description: site.siteMetadata.description,
    logo: pathToURL('/images/favicon.png'),
    name: 'OGP Validator',
    url: pathToURL(''),
  }
  const breadcrumbSchema = CreateBreadcrumbList()

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify([organizationSchema, breadcrumbSchema])}
      </script>
    </Helmet>
  )
}

export default Component
