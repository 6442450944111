import React, { useState, useEffect, useRef } from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { State, HandleSubmitFunc, Result } from '@/pages/index.tsx'
import LogoSvg from '@/assets/images/logo.svg'
import { color } from '@/assets/styles/variables'
import FormGroup from '@/components/Form/FormGroup'
import InputDefault from '@/components/Form/Input'
import LoadingComponent from '@/components/Top/Loading'
import SubmitButton from '@/components/Button/SubmitButton'
import ValidateResult from '@/components/Top/ValidateResult'
import media from '@/assets/styles/media'
import { css } from 'styled-components'

const animationTime = 1
const animationDelay = 0.5
const Wrapper = styled.div<{ state: State }>`
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: minmax(600px, 100vh);
  @media ${media.md} {
    grid-template-rows: minmax(800px, 100vh);
  }
  @media ${media.xl} {
    grid-template-rows: minmax(800px, 100vh);
  }
  ${({ state }) =>
    state === 'done' &&
    css`
      grid-template-rows: minmax(600px, auto);
      @media ${media.md} {
        grid-template-rows: minmax(800px, auto);
      }
      @media ${media.xl} {
        grid-template-rows: minmax(800px, auto);
      }
    `}
`
const Block = styled.div`
  justify-self: center;
  align-self: center;
  text-align: center;
  /* max-width: 610px; */
  width: 100%;
  /* max-height: 100%; */
  padding: 15px 20px;
  /* overflow: auto; */
`

const Logo = styled.img<{ animateStyle: FlattenSimpleInterpolation }>`
  max-width: 280px;
  filter: drop-shadow(-2px -2px 2px ${color.grayLightest})
    drop-shadow(2px 2px 2px ${color.grayLight});
  margin-bottom: 12px;
  transition: top ${animationTime}s ${animationDelay}s ease-in-out,
    transform ${animationTime}s ${animationDelay}s ease-in-out;
  @media ${media.xs} {
    max-width: 320px;
    margin-bottom: 16px;
  }
  @media ${media.sm} {
    max-width: 360px;
  }
  @media ${media.md} {
    max-width: 400px;
    margin-bottom: 24px;
  }
  @media ${media.lg} {
    max-width: 430px;
  }
  ${({ animateStyle }) => (animateStyle ? animateStyle : '')}
`
const Title = styled.div<{ animateStyle: FlattenSimpleInterpolation }>`
  font-size: 1.4rem;
  color: ${color.grayDarkest};
  letter-spacing: 0.03em;
  transition: opacity ${animationTime}s ease-in-out,
    transform ${animationTime}s ease-in-out;
  @media ${media.sm} {
    font-size: 1.6rem;
  }
  @media ${media.md} {
    font-size: 1.8rem;
  }
  @media ${media.lg} {
    font-size: 2rem;
  }
  ${({ animateStyle }) => (animateStyle ? animateStyle : '')}
`

const Form = styled.form`
  max-width: 610px;
  padding-top: 40px;
  padding-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  @media ${media.sm} {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  @media ${media.md} {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  @media ${media.lg} {
    padding-top: 80px;
    padding-bottom: 40px;
  }
`
const Input = styled(InputDefault)<{
  animateStyle: FlattenSimpleInterpolation
}>`
  max-width: 610px;
  transition: top ${animationTime}s ${animationDelay}s ease-in-out,
    transform ${animationTime}s ${animationDelay}s ease-in-out;
  ${({ animateStyle }) => (animateStyle ? animateStyle : '')}
`
const ButtonBlock = styled.div`
  @media ${media.sm} {
    margin-top: 8px;
  }
  @media ${media.md} {
    margin-top: 16px;
  }
  @media ${media.lg} {
    margin-top: 20px;
  }
`
const Button = styled(SubmitButton)<{
  type: string
  onClick: () => void
  animateStyle: FlattenSimpleInterpolation
}>`
  margin-top: 0;
  transition: opacity ${animationTime}s ease-in-out,
    transform ${animationTime}s ease-in-out, box-shadow 0.1s ease-in;
  ${({ animateStyle }) => (animateStyle ? animateStyle : '')}
`

const Loading = styled(LoadingComponent)`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 240px;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
`

const Component: React.FC<{
  result: Result
  state: State
  handleSubmit: HandleSubmitFunc
}> = ({ result, state, handleSubmit }) => {
  const [value, setValue] = useState<string>('')
  const [logoStyle, setLogoStyle] = useState<FlattenSimpleInterpolation>([])
  const [titleStyle, setTitleStyle] = useState<FlattenSimpleInterpolation>([])
  const [inputStyle, setInputStyle] = useState<FlattenSimpleInterpolation>([])
  const [buttonStyle, setButtonStyle] = useState<FlattenSimpleInterpolation>([])
  const [loadingStyle, setLoadingStyle] = useState<FlattenSimpleInterpolation>(
    []
  )
  const isExpand = state !== 'new'
  const logoRef = useRef<HTMLImageElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const animateTransition = () => {
    const logoRec = logoRef?.current?.getBoundingClientRect()
    const titleRec = titleRef?.current?.getBoundingClientRect()
    const inputRec = inputRef?.current?.getBoundingClientRect()
    const buttonRec = buttonRef?.current?.getBoundingClientRect()
    const setBaseRec = (
      rec: DOMRect | undefined,
      setStyle: typeof setLogoStyle,
      offset?: number
    ): void => {
      if (!rec) {
        return
      }
      if (offset) {
        setStyle(
          () => css`
            transform: translate(0, ${offset - rec.top}px) scale(0.8);
          `
        )
        setTimeout(() => {
          setStyle(css`
            &:not([data-state='new']) {
              position: absolute;
              top: ${offset}px;
              left: 50%;
              transform: translate(-50%, 0) scale(0.8);
              transition: none;
            }
          `)
        }, (animationTime + animationDelay) * 1000)
      } else {
        setStyle(
          () => css`
            transform: scale(0);
          `
        )
      }
    }
    setBaseRec(logoRec, setLogoStyle, 30)
    setBaseRec(titleRec, setTitleStyle)
    setBaseRec(inputRec, setInputStyle, 80)
    setBaseRec(buttonRec, setButtonStyle)
  }
  const resetAnimateTransition = () => {
    window.requestAnimationFrame(() => {
      setLogoStyle(css``)
      setTitleStyle(css``)
      setInputStyle(css``)
      setButtonStyle(css``)
    })
  }
  useEffect(() => {
    // 検索アニメーション
    if (isExpand) {
      animateTransition()
    } else {
      resetAnimateTransition()
    }
  }, [isExpand])
  useEffect(() => {
    // loading完了アニメーション
    if (state === 'new') {
      setLoadingStyle(
        () => css`
          transform: translate(-50%, -50%) scale(0);
          opacity: 0;
        `
      )
    } else if (state === 'wip') {
      setLoadingStyle(
        () => css`
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
          transition: transform 0.8s 0.5s ease-in-out,
            opacity 0.8s 0.5s ease-in-out;
        `
      )
      setTimeout(() => {
        setLoadingStyle(
          state => css`
            ${state}
            &[data-state='done'] {
              transform: translate(-50%, -50%) scale(0);
              opacity: 0;
              transition: transform 0.3s ease-out, opacity 0.3s ease-out;
            }
          `
        )
      }, 1000)
    }
    return
  }, [state])

  const resultProps = {
    state,
    result,
  }

  return (
    <Wrapper state={state}>
      <Block>
        <h1>
          <Logo
            src={LogoSvg}
            data-state={state}
            ref={logoRef}
            animateStyle={logoStyle}
          ></Logo>
          <Title data-state={state} ref={titleRef} animateStyle={titleStyle}>
            Twitter & FacebookのOGP更新ツール
          </Title>
        </h1>
        <Form onSubmit={e => handleSubmit(e, value)}>
          <FormGroup>
            <Input
              type="text"
              placeholder="https://your-site.com/"
              value={value}
              onChange={e => setValue(e.target.value)}
              data-state={state}
              ref={inputRef}
              animateStyle={inputStyle}
              autoFocus={true}
            />
          </FormGroup>
          <ButtonBlock>
            <Button
              type="submit"
              data-state={state}
              ref={buttonRef}
              animateStyle={buttonStyle}
            />
          </ButtonBlock>
        </Form>
        <Loading css={loadingStyle} data-state={state}></Loading>
        <ValidateResult {...resultProps} />
      </Block>
    </Wrapper>
  )
}

export default Component
