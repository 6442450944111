import React, { forwardRef } from 'react'

interface P {
  onMouseEnter?: MouseEvent
  onMouseLeave?: MouseEvent
  onTouchStart?: MouseEvent
  onTouchEnd?: MouseEvent
  className?: string
}
interface State {
  isHover: boolean
}

interface MouseEvent {
  (
    e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>
  ): void
}

type WrappedComponentType<PP> =
  | React.ComponentType<PP>
  | React.ForwardRefExoticComponent<PP>

const HoverProvider = <ComponentProps extends Record<string, unknown>>(
  WrappedComponent: WrappedComponentType<ComponentProps>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<ComponentProps & P> &
    React.RefAttributes<typeof WrappedComponent>
> => {
  type WrappedProps = ComponentProps & P
  type WrappedPropsWithRef = WrappedProps & {
    hoverRef?: React.Ref<unknown>
  }
  class HoverProviderComponent extends React.Component<
    WrappedPropsWithRef,
    State
  > {
    constructor(props: WrappedPropsWithRef) {
      super(props)

      this.state = {
        isHover: false,
      }
    }

    onMouseEnter: MouseEvent = e => {
      const { onMouseEnter } = this.props
      if (onMouseEnter) onMouseEnter(e)
      this.setState({ isHover: true })
    }

    onMouseLeave: MouseEvent = e => {
      const { onMouseLeave } = this.props
      if (onMouseLeave) onMouseLeave(e)
      this.setState({ isHover: false })
    }

    onTouchStart: MouseEvent = e => {
      const { onTouchStart } = this.props
      if (onTouchStart) onTouchStart(e)
      this.setState({ isHover: true })
    }

    onTouchEnd: MouseEvent = e => {
      const { onTouchEnd } = this.props
      if (onTouchEnd) onTouchEnd(e)
      this.setState({ isHover: false })
    }

    render() {
      const { isHover } = this.state
      const { children, hoverRef, ...others } = this.props

      return (
        <WrappedComponent
          {...(others as WrappedProps)}
          data-active={isHover}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
          ref={hoverRef}
        >
          {children}
        </WrappedComponent>
      )
    }
  }
  const HoverProviderComponentWithForwardRef = forwardRef<
    typeof WrappedComponent,
    WrappedProps
  >((props, ref) => <HoverProviderComponent hoverRef={ref} {...props} />)
  HoverProviderComponentWithForwardRef.displayName =
    'HoverProviderComponentWithForwardRef'
  return HoverProviderComponentWithForwardRef
}

export default HoverProvider
