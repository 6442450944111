import React from 'react'
import Global from '@/assets/styles/global'

const App: React.FC = () => (
  <>
    <Global />
  </>
)

export default App
