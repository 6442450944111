import { BreadcrumbList, ListItem, WithContext } from 'schema-dts'

type Item = {
  path: string
  name: string
}

type CreateFucntion = {
  (items?: Item[]): WithContext<BreadcrumbList>
}

const pathToURL = (path = ''): string => {
  const url = new URL(path, process.env.GATSBY_URL)
  if (!url) {
    return ''
  }
  return url.href
}

export const CreateBreadcrumbList: CreateFucntion = (items = []) => {
  const itemList: ListItem[] = items.map(i => ({
    '@type': 'ListItem',
    item: {
      '@id': pathToURL(i.path),
      name: i.name,
    },
  }))
  const topItem: ListItem = {
    '@type': 'ListItem',
    item: {
      '@id': pathToURL(''),
      name: 'OGP Validator',
    },
  }
  const itemListElement = [topItem, ...itemList].map((item, index) => {
    item.position = index + 1
    return item
  })
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  }
}
