type Size = keyof SizesDeclaration
type SizesDeclaration = {
  xl?: string
  lg?: string
  md?: string
  sm?: string
  xs?: string
}
type CustomDeclaration = {
  custom: (n: number) => string
}

const sizes: { [s in Size]: number } = {
  xl: 1160,
  lg: 900,
  md: 768,
  sm: 480,
  xs: 321,
}

const customMap: CustomDeclaration = {
  custom: size => `(min-width: ${size}px)`,
}

const sizesMap: SizesDeclaration = (Object.keys(sizes) as Size[]).reduce(
  (acc: SizesDeclaration, label): SizesDeclaration => {
    if (!acc[label]) {
      acc[label] = `(min-width: ${sizes[label]}px)`
    }
    return acc
  },
  {}
)

export default { ...sizesMap, ...customMap }
