import styled from 'styled-components'
import media from '@/assets/styles/media'

const FormGroup = styled.div`
  margin-bottom: 30px;
  @media ${media.md} {
    margin-bottom: 40px;
  }
`

export default FormGroup
