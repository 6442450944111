import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import HoverProvider from '@/components/Button/HoverProvider'
import { color } from '@/assets/styles/variables'

const mixinButton = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: ${color.white};
  line-height: 1.5;
  background-color: ${color.primary};
  border: 1px solid;
  border-color: ${color.primary};
  border-radius: 3em;
  padding: 0.58em 2em;
  cursor: pointer;
  &[data-active='true'] {
    color: ${color.primary};
    background-color: ${color.white};
  }
`

const Button = styled.button`
  ${mixinButton}
`

const Component = forwardRef<HTMLButtonElement, Record<string, unknown>>(
  ({ children, ...others }, ref) => {
    return (
      <Button {...others} ref={ref}>
        {children}
      </Button>
    )
  }
)
Component.displayName = 'ButtonBase'

export default HoverProvider(Component)
