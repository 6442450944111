import React from 'react'
import styled, { css } from 'styled-components'
import { color } from '@/assets/styles/variables'
import { Result, State } from '@/pages'
import media from '@/assets/styles/media'

const animationDelay = 1
const Wrapper = styled.div`
  height: 0;
  padding: 20px;
  margin: -20px;
  overflow: hidden;
  &[data-state='done'] {
    height: auto;
  }
`
const Block = styled.div`
  width: 100%;
  max-width: 1000px;
  background-color: ${color.grayLighter};
  border-radius: 60px;
  box-shadow: 0 0 0 ${color.grayLight}, 0 0 0 ${color.grayLightest};
  padding: 40px 10px;
  margin-right: auto;
  margin-left: auto;
  @media ${media.xl} {
    padding: 60px 20px;
  }
  &[data-state='done'] {
    box-shadow: 6px 6px 12px ${color.grayLight},
      -6px -6px 12px ${color.grayLightest};
    transition: box-shadow 1s ease-in-out;
  }
`
const Text = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: ${color.grayDarkest};
  line-height: 1.75;
  margin-bottom: 1.5em;
  @media ${media.xl} {
    font-size: 2.4rem;
  }
  & span {
    display: inline-block;
  }
  &:not([data-state='done']) {
    transform: scale(0);
  }
  &[data-state='done'] {
    transform: scale(1);
    transition: transform 0.5s ${animationDelay + 0.3}s ease-in-out;
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
const ListItem = styled.div`
  flex: 0 1 100%;
  max-width: 420px;
  min-width: 260px;
  @media ${media.sm} {
    flex: 0 1 50%;
    min-width: 320px;
  }
`
const Item = styled.div`
  padding: 20px 10px;
  &:not([data-state='done']) {
    transform: scale(0);
  }
  &[data-state='done'] {
    transform: scale(1);
    transition: transform 0.5s ${animationDelay + 0.8}s ease-in-out;
  }
`
const Label = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${color.grayDarkest};
  margin-bottom: 1em;
  @media ${media.xl} {
    font-size: 2.4rem;
  }
`
const Image = styled.img<{ type?: string }>`
  width: 100%;
  height: auto;
  ${({ type }) =>
    type === 'twitter' &&
    css`
      border-radius: 10px;
    `}
`
const NoImage = styled.div`
  font-size: 1.6rem;
  text-align: center;
  background-color: ${color.grayLight};
  border-radius: 10px;
  width: 100%;
  padding: 3em 0;
`

const ValidateResult: React.FC<{ result: Result; state: State }> = ({
  result,
  state,
}) => {
  return (
    <Wrapper data-state={state}>
      <Block data-state={state}>
        <Text data-state={state}>
          <span>お待たせしました！</span>
          <span>解析結果がでました！</span>
        </Text>
        <List>
          <ListItem>
            <Item data-state={state}>
              <Label>Twitter</Label>
              {result.twitter ? (
                <Image src={result.twitter} type="twitter" />
              ) : (
                <NoImage>解析に失敗しました</NoImage>
              )}
            </Item>
          </ListItem>
          <ListItem>
            <Item data-state={state}>
              <Label>Facebook</Label>
              {result.facebook ? (
                <Image src={result.facebook} />
              ) : (
                <NoImage>解析に失敗しました</NoImage>
              )}
            </Item>
          </ListItem>
        </List>
      </Block>
    </Wrapper>
  )
}

export default ValidateResult
