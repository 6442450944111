import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { color } from './variables'

export default createGlobalStyle`
${reset}
html {
  font-family:
    "Roboto",
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    "BIZ UDPGothic",
    Meiryo,
    sans-serif;
  /* font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif; */
  font-size: 62.5%; /*ベースを10pxにしています*/
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
body {
  font-size: 1.0rem; /*10px*/
  background-color: ${color.grayLighter};
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button, input, select, textarea {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

li {
  list-style-type: none;
}

img {
  max-width: 100%;
}
`
